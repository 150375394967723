/**
 * 交易公告接口管理
 */
import service from '../request'

const baseUrl = `${window.globalUrl.HOME_API_WEI}/trade-website/cqjyService`;

// 获取需求信息公告
export const getNeedsAnnouncement = (params) => {
  // return service.get(`${baseUrl}/queryGpggPage`, params)
  return service({
    method: 'get',
    url: `${baseUrl}/queryGpggPage`,
    params: params
  })
}

// 获取成交公示公告
export const getSuccessAnnouncement = (params) => {
  // return service.post(`${baseUrl}/getDealNotice`, params, {isForm: true})
  return service({
    method: 'get',
    url: `${baseUrl}/queryCjggPage`,
    params: params
  })
}

// 资金监管公示
export const getSupervisionAnnouncement = (params) => {
  // return service.post(`${baseUrl}/getFundSupervisionNotice`, params, {isForm: true})
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API_WEI}/admin/cms/page`,
    params: params
  })
}

// 审核备案结果
export const getAuditResAnnouncement = (params) => {
  return service.post(`${baseUrl}/getShbaNotice`, params, {isForm: true})
}

// 根据需求编号获取需求详情
export const getNeedsDetail = (params) => {
  return service.post(`${baseUrl}/getDemandDetailByXqbh`, params, {isForm: true})
}

// 根据成交项目编号获取成交详情
export const getSuccessDetail = (params) => {
  return service.post(`${baseUrl}/getDealDetailByXmbh`, params, {isForm: true})
}
